import React, { Suspense, useEffect } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import './layout.css'
import { useState } from 'react'
import Loading from './Loading';
export default function Layout() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation()

    useEffect(() => {
        setIsMenuOpen(false)
        window.scrollTo({ top: 0 })
    }, [location.pathname])

    return (
        <>
            <header className="bg-gray-800 py-4 px-4 fixed top-0 left-0 w-full z-50 border-b border-gray-300">
                <div className="container mx-auto flex items-center justify-between">
                    <Link to='/' className="flex items-center">
                        <img src="logo.png" alt="Ayo Ngaji" className="h-8 mr-2" />
                        <h1 className="text-white text-lg font-bold">Pondok Pesantren Ayo Ngaji</h1>
                    </Link>
                    <nav className="hidden md:flex space-x-4">
                        <Link to="/" className="text-white hover:text-gray-300">Beranda</Link>
                        {/* <Link to="/berita" className="text-white hover:text-gray-300">Berita</Link> */}
                        {/* <Link to="/galeri" className="text-white hover:text-gray-300">Galeri</Link> */}
                        {/* <Link to="/tulisan" className="text-white hover:text-gray-300">Tulisan</Link> */}
                        <Link to="/tentang" className="text-white hover:text-gray-300">Tentang Kami</Link>
                        {/* <Link to="/kontak" className="text-white hover:text-gray-300">Kontak Kami</Link> */}
                    </nav>
                    <div className="md:hidden">
                        <button
                            className="text-white focus:outline-none"
                            onClick={() => setIsMenuOpen(!isMenuOpen)}
                        >
                            {isMenuOpen ? (
                                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            ) : (
                                <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
                                </svg>
                            )}
                        </button>
                    </div>
                    {isMenuOpen && (
                        <div className="md:hidden absolute top-16 inset-x-0 bg-gray-800 text-white py-2 px-4">
                            <Link to="/" className="block py-2">Beranda</Link>
                            {/* <Link to="/berita" className="block py-2">Berita</Link> */}
                            {/* <Link to="/galeri" className="block py-2">Galeri</Link> */}
                            {/* <Link to="/tulisan" className="block py-2">Tulisan</Link> */}
                            <Link to="/tentang" className="block py-2">Tentang Kami</Link>
                            {/* <Link to="/kontak" className="block py-2">Kontak Kami</Link> */}
                        </div>
                    )}
                </div>
            </header>
            <main className='main-content bg-gray-100'>
                <Suspense fallback={<Loading />}>
                    <Outlet />
                </Suspense>
            </main>
            <footer className="bg-gray-800 text-white lg:pt-12 md:pt-12">
                <div className="container mx-auto lg:grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
                    {/* Kolom 1: Logo */}
                    <Link to={'/'} className='flex lg:px-8 justify-center'>
                        <img src="logo.png" alt="Logo" className="w-64" />
                    </Link>

                    <hr className="lg:hidden md:hidden border-t border-gray-600 my-4" />

                    <div className='col-span-2'>
                        <div className="mb-4">
                            <h3 className="text-lg font-semibold mb-2">Kontak</h3>
                            <p><a href="https://wa.me/6285142614824" className="hover:text-gray-400">+62 851-4261-4824</a></p>
                        </div>
                        <hr className="border-t border-gray-600 my-4" />
                        <div>
                            <div className='lg:flex gap-16'>
                                <div className='mb-4'>
                                    <h3 className="text-lg font-semibold mb-2">Social Media</h3>
                                    <ul>
                                        <li className="mb-2"><a href="https://www.instagram.com/ponpes_ayongaji_kedungkebo/" className="hover:text-gray-400">Instagram @ponpes_ayongaji_kedungkebo</a></li>
                                        <li className="mb-2"><a href="https://www.youtube.com/@AyoNgajiKedungkebo" className="hover:text-gray-400">YouTube @AyoNgajiKedungkebo</a></li>
                                        <li className="mb-2"><a href="https://www.tiktok.com/@ponpesayongajikedungkebo" className="hover:text-gray-400">Tiktok @ponpesayongajikedungkebo</a></li>
                                        <li className="mb-2"><a href="https://www.facebook.com/people/Ponpes-Ayo-Ngaji-Kedungkebo/100077506460181" className="hover:text-gray-400">Facebook @Ponpes Ayo Ngaji Kedungkebo</a></li>
                                    </ul>
                                </div>
                                <hr className="lg:hidden border-t border-gray-600 my-4" />
                                <div>
                                    <h3 className="text-lg font-semibold mb-2">Media</h3>
                                    <ul>
                                        <li className="mb-2"><a href="https://www.tiktok.com/@kedaibincang_official" className="hover:text-gray-400">Tiktok @kedaibincang_official</a></li>
                                        <li className="mb-2"><a href="https://www.youtube.com/@KedaiBincang" className="hover:text-gray-400">YouTube @KedaiBincang</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Separator */}
                <hr className="border-t border-gray-600 my-8" />

                {/* Copyright */}
                <div className="container mx-auto text-center px-4 pb-8">
                    <p>&copy; 2024 Pondok Pesantren Ayo Ngaji. All rights reserved.</p>
                </div>
            </footer>
        </>
    )
}

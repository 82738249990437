import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import Layout from "./layout";

const Home = lazy(() => import('./Home'));
const Tentang = lazy(() => import('./Tentang'));

const router = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: '/',
                index: true,
                element: <Home />
            },
            {
                path: '/tentang',
                element: <Tentang />
            },

        ],
        errorElement: <div>Not Found</div>
    },
]);

export default router
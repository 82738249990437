import React from 'react'

export default function Loading() {
    return (
        <section className="flex justify-center flex-col gap-2 items-center min-h-screen bg-gray-100">
            <div className="w-16 h-16 border-4 border-gray-400 rounded-full border-t-4 border-t-blue-500 animate-spin"></div>
            <p className='text-center'>Loading ...</p>
        </section>
    )
}
